<template>
  <div id="invite">
    <div class="swiper-pc">
      <img
        class="banner-img"
        :src="
          `https://esim.qn.triproaming.cn/banner/invite_${$i18n.locale}.jpg`
        "
        alt=""
      />
    </div>
    <div class="swiper-xs">
      <img
        class="banner-img"
        :src="
          `https://esim.qn.triproaming.cn/banner/invite_${$i18n.locale}_xs.jpg`
        "
        alt=""
      />
    </div>
    <div class="invite-code-pc flex">
      <div>{{ $t("invite.code") }}</div>
      <div class="code">{{ info.userInfo.division_invite }}</div>
      <van-button
        @click="copy('.code-copy', info.userInfo.division_invite)"
        class="code-copy"
        round
        size="large"
        color="linear-gradient(358deg, #773DE1 0%, #893CDB 33%, #E538BC 100%)"
      >
        {{ $t("invite.copy") }}
      </van-button>
    </div>
    <div class="container">
      <div class="invite-container-pc">
        <div class="title">{{ $t("invite.text_1") }}</div>
        <div class="rule" @click="ruleHandle" v-if="isLogin">
          {{ $t("invite.rules") }}
        </div>
        <div class="step">
          <div class="step-item">
            <div class="flex-center-between">
              <span class="number">1</span>
              <van-image
                width="50"
                height="50"
                fit="contain"
                :src="require('@/assets/invite_step_1.png')"
              />
            </div>
            <div class="title">{{ $t("invite.text_2") }}</div>
            <div>{{ $t("invite.text_3") }}</div>
          </div>
          <div class="step-item">
            <div class="flex-center-between">
              <span class="number">2</span>
              <van-image
                width="50"
                height="50"
                fit="contain"
                :src="require('@/assets/invite_step_2.png')"
              />
            </div>
            <div class="title">{{ $t("invite.text_4") }}</div>
            <div>{{ $t("invite.text_5") }}</div>
          </div>
          <div class="step-item">
            <div class="flex-center-between">
              <span class="number">3</span>
              <van-image
                width="50"
                height="50"
                fit="contain"
                :src="require('@/assets/invite_step_3.png')"
              />
            </div>
            <div class="title">{{ $t("invite.text_6") }}</div>
            <div>{{ $t("invite.text_7") }}</div>
          </div>
        </div>
        <div class="flex" style="margin-bottom: 120px;">
          <van-button
            @click="share('.share-pc')"
            class="share-pc"
            round
            size="large"
            color="linear-gradient(358deg, #773DE1 0%, #893CDB 33%, #E538BC 100%)"
          >
            {{ $t("invite.share") }}
          </van-button>
        </div>
      </div>
      <div class="invite-container-xs">
        <div class="invite-code">
          <div class="title">{{ $t("invite.mycode") }}</div>
          <div class="code flex">
            <span>{{ info.userInfo.division_invite }}</span>
            <span
              v-if="isLogin"
              class="copy"
              @click="copy('.copy', info.userInfo.division_invite)"
              >{{ $t("invite.copy") }}</span
            >
          </div>
        </div>
        <div class="invite-explain">
          <div class="title">
            {{ $t("invite.text_1") }}
            <span v-if="isLogin" class="rule" @click="ruleHandle">{{
              $t("invite.rules")
            }}</span>
          </div>
          <div class="steps">
            <div class="step-item">
              <div>
                <van-tag size="medium" round color="#8032EA">1</van-tag>
                <span class="title">{{ $t("invite.text_2") }}</span>
              </div>
              <div class="text">{{ $t("invite.text_3") }}</div>
            </div>
            <div class="step-item">
              <div>
                <van-tag size="medium" round color="#8032EA">2</van-tag>
                <span class="title">{{ $t("invite.text_4") }}</span>
              </div>
              <div class="text">{{ $t("invite.text_5") }}</div>
            </div>
            <div class="step-item">
              <div>
                <van-tag size="medium" round color="#8032EA">3</van-tag>
                <span class="title">{{ $t("invite.text_6") }}</span>
              </div>
              <div class="text">{{ $t("invite.text_7") }}</div>
            </div>
          </div>
          <van-button
            round
            @click="share('.share-xs')"
            class="share-xs"
            size="large"
            color="linear-gradient(358deg, #773DE1 0%, #893CDB 33%, #E538BC 100%)"
          >
            {{ $t("invite.share") }}
          </van-button>
        </div>
      </div>
      <van-divider class="divider">{{ $t("invite.records") }}</van-divider>
      <div class="records" v-if="info.spread.count">
        <a-row>
          <a-col :span="12">
            <div class="record-item item-border">
              <div class="record-number">{{ info.spread.count }}</div>
              <div class="record-text">{{ $t("invite.invited") }}</div>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="record-item">
              <div class="record-number flex">
                {{ info.spread.count }}
                <a-button
                  @click="handleUse"
                  class="use-btn"
                  type="primary"
                  shape="round"
                  size="small"
                  >{{ $t("invite.use") }}</a-button
                >
              </div>
              <div class="record-text">{{ $t("invite.coupons") }}</div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-else class="empty">{{ $t("invite.no_record") }}</div>
    </div>
    <van-overlay :show="shareShow" :lock-scroll="false" z-index="1000">
      <div class="share-wrapper" @click.stop>
        <div class="share-inner">
          <div class="flex-center-end">
            <van-icon
              color="#666666"
              class="close"
              @click="shareShow = false"
              style="cursor: pointer;"
              size="24px"
              name="cross"
            />
          </div>
          <div class="share-cont">
            <van-image fit="contain" :src="require('@/assets/share.png')" />
            <div class="title">{{ $t("invite.text_8") }}</div>
            <div class="text">{{ $t("invite.text_9") }}</div>
            <van-button
              @click="shareShow = false"
              style="width: 88px;"
              round
              size="small"
              color="linear-gradient(358deg, #773DE1 0%, #893CDB 33%, #E538BC 100%)"
            >
              {{ $t("invite.know") }}
            </van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Clipboard from "clipboard";
import { message } from "ant-design-vue";
import { UserInviteInfoAPI } from "../api/api";
import {
  handleAppActionLogin,
  handleAppActionBackToHome,
  handleAppActionCloseWebView,
  handleAppActionShare,
} from "../utils/appActions";
export default {
  name: "Invite",
  data() {
    return {
      shareShow: false,
      info: {
        spread: {
          count: 0,
        },
        spreadDesc: "",
        userInfo: {
          division_invite: "",
        },
      },
    };
  },
  computed: {
    ...mapState(["isApp"]),
    ...mapGetters("user", ["isLogin"]),
  },
  mounted() {
    console.log("ReactNativeWebView-", window.ReactNativeWebView);
    window.webRefresh = (token) => {
      if (!token) {
        console.log("webRefresh 没有token");
        handleAppActionCloseWebView()
        return;
      }
      console.log("webRefresh 更新token");
      this.AuthTokenLogin(decodeURIComponent(token));
      this.init();
    };
    this.init();
  },
  methods: {
    ...mapActions("user", ["AuthTokenLogin"]),
    async init() {
      if (this.isApp && !this.isLogin) {
        handleAppActionLogin();
        return;
      }
      const result = await UserInviteInfoAPI();
      if (this.isApp && result.code == 401) {
        setTimeout(() => {
          handleAppActionLogin();
        }, 2000);
        return;
      }
      if (result.code == 1) this.info = result.data;
    },
    ruleHandle() {
      this.$router.push({ name: "InviteRules" });
    },
    handleUse() {
      if (this.isApp && !this.isLogin) {
        handleAppActionLogin();
        return;
      }
      if (this.isApp) {
        handleAppActionBackToHome();
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    share(node) {
      if (this.isApp && !this.isLogin) {
        handleAppActionLogin();
        return;
      }
      if (this.isApp) {
        handleAppActionShare(this.info.spreadDesc)
      } else {
        this.copy(node, this.info.spreadDesc);
      }
    },
    copy(node, text) {
      let clipboard = new Clipboard(node, {
        // 点击copy按钮，直接通过text直接返回复印的内容
        text: () => text,
      });
      clipboard.on("success", (e) => {
        // 释放内存
        node.includes("share")
          ? (this.shareShow = true)
          : message.success(this.$t("mine.flow_3"));
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        message.warning(this.$t("mine.flow_4"));
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.banner-img {
  width: 100%;
  height: auto;
}
.share-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .share-inner {
    width: 320px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 16px;
    .share-cont {
      padding: 0 20px 20px 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title {
        color: #222222;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
      }
      .text {
        text-align: center;
        color: #222222;
        font-size: 12px;
        margin: 10px 0 15px 0;
      }
    }
  }
}
#invite {
  padding-bottom: 40px;

  .divider {
    font-size: 32px;
    color: #222222;
    border-color: #b5b5b5;
    margin: 120px 0 80px 0;
  }
  .records {
    width: 70%;
    margin: 0 auto;
    box-shadow: 0px 2px 10px 0px rgba(23, 59, 92, 0.08);
    background: #ffffff;
    border-radius: 16px;
    padding: 20px 0;
    text-align: center;
    .record-item {
      padding: 0 10px;
    }
    .item-border {
      border-right: 1px solid #999999;
    }
    .record-number {
      color: #8032ea;
      font-size: 24px;
      font-weight: bold;
    }
    .record-text {
      margin-top: 4px;
      color: #5d5d5d;
      font-size: 18px;
    }
    .use-btn {
      margin-left: 10px;
      border-color: $color;
      background-color: $color;
    }
  }
  .empty {
    color: #333333;
    font-size: 18px;
    text-align: center;
  }
  .swiper-pc {
    display: block;
  }
  .swiper-xs {
    display: none;
  }
  .invite-code-pc {
    height: 180px;
    background-image: url("../assets/invite_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 28px;
    color: #222222;
    .code {
      color: #8032ea;
      font-size: 50px;
      font-weight: 900;
      margin: 0 40px;
    }
    .code-copy {
      width: 140px;
    }
  }
  .invite-container-pc {
    .title {
      margin: 80px 0 20px 0;
      color: #222222;
      font-size: 32px;
      text-align: center;
    }
    .rule {
      font-size: 18px;
      color: #8032ea;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
    .step {
      display: flex;
      justify-content: center;
      flex-flow: wrap;
      .step-item {
        margin: 40px;
        width: 269px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cccccc;
        padding: 40px 40px 40px 30px;
        color: #333333;
        font-size: 14px;
        .number {
          font-size: 50px;
          font-weight: 900;
        }
        .title {
          margin: 10px 0;
          font-size: 18px;
          font-weight: bold;
          text-align: start;
        }
      }
    }
    .share-pc {
      width: 340px;
    }
  }
  .invite-container-xs {
    display: none;
  }
}
@media (max-width: 768px) {
  #invite {
    padding-bottom: 20px;
    .container {
      padding: 0 20px;
    }
    .divider {
      font-size: 14px;
      color: #5d5d5d;
      border-color: #b5b5b5;
      margin: 15px 0;
    }
    .records {
      width: 100%;
      box-shadow: none;
      padding: 10px 0;
      background: #ffffff;
      .record-item {
        padding: 0 6px;
      }
      .record-number {
        color: #8032ea;
        font-size: 24px;
        font-weight: bold;
      }
      .record-text {
        margin-top: 2px;
        color: #5d5d5d;
        font-size: 14px;
      }
      .use-btn {
        font-size: 12px;
        margin-left: 6px;
      }
    }
    .empty {
      color: #5d5d5d;
      font-size: 14px;
      text-align: center;
    }
    .swiper-pc {
      display: none;
    }
    .swiper-xs {
      display: block;
    }
    .invite-code-pc {
      display: none;
    }
    .invite-container-pc {
      display: none;
    }
    .invite-container-xs {
      margin-top: -30px;
      display: block;
      .invite-explain {
        padding: 0 20px 25px 20px;
        background: #f6f0fd;
        border-radius: 0 0 15px 15px;
        .title {
          font-size: 16px;
          color: #222222;
          .rule {
            margin-left: 10px;
            font-size: 12px;
            color: #8032ea;
          }
        }
        .steps {
          margin: 15px 0 20px 0;

          .step-item {
            position: relative;
            padding-bottom: 15px;
            &::before {
              position: absolute;
              content: "";
              width: 2px;
              height: 100%;
              left: 10px;
              top: 16px;
              border-left: 1px dashed #8032ea;
            }
            &:last-child {
              padding-bottom: 0;
              &::before {
                display: none;
              }
            }
            .title {
              color: #222222;
              font-size: 14px;
              margin-left: 10px;
              font-weight: bold;
            }
            .text {
              font-size: 14px;
              color: #5d5d5d;
              margin-left: 23px;
            }
          }
        }
      }
      .invite-code {
        position: relative;
        height: 140px;
        padding: 15px 20px;
        border-radius: 15px 15px 0 0;
        background: radial-gradient(
            circle at 15px 120px,
            transparent 0%,
            transparent 15px,
            #f6f0fd 15px
          ) -15px;
        &::after {
          content: "";
          width: 88%;
          height: 4px;
          border-bottom-width: 3px;
          border-bottom-style: dashed;
          transform: translateX(-50%);
          border-color: #ffffff;
          position: absolute;
          left: 50%;
          bottom: 18px;
        }
        .title {
          font-size: 14px;
          text-align: center;
          color: #5d5d5d;
          margin-bottom: 10px;
        }
        .code {
          color: #8032ea;
          font-size: 28px;
          font-weight: bold;
          height: 60px;
          background-color: #fff;
          box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
          border-radius: 15px;
          .copy {
            color: #5d5d5d;
            margin-left: 20px;
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
